import { Component, Input, computed, inject, input } from '@angular/core';
import { SubscriptionType } from '../../../../utils/api';
import { HelpersService } from '../../../services/helpers.service';

@Component({
    selector: 'app-avatar',
    imports: [],
    templateUrl: './avatar.component.html',
    styleUrl: './avatar.component.scss'
})
export class AvatarComponent {
    @Input() size: number;
    @Input() fontSize: number;
    @Input() type: SubscriptionType;
    image = input<string>();
    borderWidth = input<number>(2);
    classes = input<'app-avatar--light'[]>([]);
    joinedClasses = computed(() => this.classes().join(' '));
    firstname = input<string>();
    lastname = input<string>();
    fullname = input<string>();
    initials = input<string>();
    computedInitials = computed(() => {
        let firstname = this.firstname();
        let lastname = this.lastname();
        if ((!this.firstname() || !this.lastname()) && this.fullname()) {
            firstname = this.fullname().split(' ')[0];
            lastname = this.fullname().split(' ')[1];
        }
        return `${(firstname ?? '').trim().charAt(0)}${(lastname ?? '').trim().charAt(0)}`?.toUpperCase();
    });
    helpersService = inject(HelpersService);
    color = computed(() => {
        return this.helpersService.getColorBySubscriptionType(this.type);
    });
    backgroundColor = computed(() => {
        if (this.classes().includes('app-avatar--light')) {
            return '#fff';
        }
        return 'transparent';
    });
    borderColor = computed(() => {
        if (this.classes().includes('app-avatar--light')) {
            return 'transparent';
        }
        return this.helpersService.getColorBySubscriptionType(this.type);
    });
    borderWidthComputed = computed(() => {
        if (this.classes().includes('app-avatar--light') && this.image()) {
            return 0;
        }
        return this.borderWidth();
    });
}
