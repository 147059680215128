{
  "name": "medbook",
  "version": "2.3.23",
  "description": "Medbook",
  "private": true,
  "engines": {
    "node": "22.x.x",
    "npm": "10.x.x"
  },
  "scripts": {
    "start": "node backend/dist/index.js",
    "test": "cd backend && npm run test",
    "batch": "cd backend && npm run batch",
    "backend:watch": "cd backend && npm run dev:watch",
    "backend:tunnel:watch": "cd backend && npm run dev:watch:tunnel",
    "build": "cd backend && tsc && cd .. && cd client && webpack --env production --mode production --config webpack.config.js && cd .. && cd promo && npm run build && cd .. && cd angular && npm run build",
    "sdk": "cd backend && npm run sdk",
    "sdk:watch": "cd backend && npm run sdk:watch",
    "angular:watch": "cd angular && npm run angular:watch",
    "promo:watch": "cd promo && npm run promo:watch",
    "react:watch": "cd client && webpack --watch",
    "dev:analyze": "cd client && webpack --analyze",
    "build:analyze": "cd client && webpack --analyze --env production",
    "dev:watch": "concurrently --names \"React,Angular,Backend,SDK\" -c \"bgGreen.bold,bgRed.bold,bgBlue.bold,bgMagenta.bold\" \"npm run react:watch\" \"npm run angular:watch\" \"npm run backend:watch\" \"npm run sdk:watch\"",
    "dev:watch:react": "concurrently --names \"React,Backend,SDK\" -c \"bgGreen.boldbgBlue.bold,bgMagenta.bold\" \"npm run react:watch\" \"npm run backend:watch\" \"npm run sdk:watch\"",
    "dev:watch:angular": "concurrently --names \"Angular,Backend,SDK\" -c \"bgRed.bold,bgBlue.bold,bgMagenta.bold\" \"npm run angular:watch\" \"npm run backend:watch\" \"npm run sdk:watch\"",
    "dev:watch:promo": "npm run promo:watch",
    "postinstall": "cd backend && npm i && cd .. && cd angular && npm i",
    "release": "generate-release"
  },
  "repository": {
    "type": "git"
  },
  "browserslist": {
    "production": [
      ">0.3%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "overrides": {
    "react-vis": {
      "react": "$react",
      "react-dom": "$react-dom"
    },
    "react-motion": {
      "react": "$react",
      "react-dom": "$react-dom"
    },
    "lodash": "^4.17.21"
  },
  "dependencies": {
    "@babel/cli": "^7.23.0",
    "@babel/core": "^7.23.2",
    "@babel/plugin-syntax-dynamic-import": "^7.8.3",
    "@babel/plugin-transform-class-properties": "^7.24.1",
    "@babel/plugin-transform-flow-strip-types": "^7.22.5",
    "@babel/plugin-transform-runtime": "^7.23.2",
    "@babel/preset-env": "^7.23.2",
    "@babel/preset-flow": "^7.22.15",
    "@babel/preset-react": "^7.22.15",
    "@babel/preset-typescript": "^7.23.2",
    "@babel/runtime": "^7.23.2",
    "@babel/runtime-corejs3": "^7.23.2",
    "@openapitools/openapi-generator-cli": "^2.6.0",
    "@redux-devtools/extension": "^3.3.0",
    "@reduxjs/toolkit": "^2.2.3",
    "@types/lodash": "^4.14.194",
    "@types/react": "^18.0.37",
    "@types/react-dom": "^18.0.11",
    "@types/react-modal": "^3.13.1",
    "@types/react-redux": "^7.1.25",
    "acorn": "^8.8.2",
    "axios": "^1.3.5",
    "babel-loader": "^9.1.2",
    "babel-plugin-lodash": "^3.3.4",
    "body-scroll-lock": "^3.1.5",
    "copy-webpack-plugin": "^11.0.0",
    "core-js": "^3.30.1",
    "css-loader": "^6.7.3",
    "dompurify": "^3.0.2",
    "fast-deep-equal": "^3.1.3",
    "formik": "^2.4.5",
    "history": "^5.3.0",
    "moment": "^2.29.4",
    "rc-pagination": "^3.3.1",
    "rc-tooltip": "^5.2.2",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-dropzone": "^14.3.5",
    "react-intl": "^6.4.0",
    "react-modal": "^3.16.1",
    "react-query": "^3.39.3",
    "react-quill-new": "^3.3.3",
    "react-redux": "^8.0.5",
    "react-router-dom": "^5.3.4",
    "react-toastify": "^9.1.2",
    "react-vis": "^1.12.1",
    "redux": "^4.2.1",
    "regenerator-runtime": "^0.13.11",
    "source-map-loader": "^4.0.1",
    "stacktrace-gps": "^3.1.2",
    "stacktrace-js": "^2.0.2",
    "style-loader": "^3.3.2",
    "ts-loader": "^9.5.1",
    "typescript": "^4.9.5",
    "url": "^0.11.0",
    "uuid": "^9.0.0",
    "validator": "^13.9.0",
    "webpack": "^5.91.0",
    "webpack-cli": "^5.0.1",
    "yup": "^1.1.1"
  },
  "devDependencies": {
    "@types/mocha": "^10.0.1",
    "@types/uuid": "^9.0.1",
    "@types/validator": "^13.7.15",
    "@typescript-eslint/eslint-plugin": "^5.59.0",
    "@typescript-eslint/parser": "^5.59.0",
    "app-root-path": "^3.1.0",
    "chai": "^4.3.7",
    "compression-webpack-plugin": "^10.0.0",
    "concurrently": "^8.0.1",
    "dotenv": "^16.0.3",
    "eslint": "^8.38.0",
    "eslint-config-prettier": "^8.8.0",
    "eslint-plugin-imengine": "file:eslint",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-react": "^7.32.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "express-openapi-validate": "^0.6.1",
    "generate-release": "^1.1.1",
    "html-webpack-plugin": "^5.5.1",
    "js-yaml": "^4.1.0",
    "lodash-webpack-plugin": "^0.11.6",
    "mini-css-extract-plugin": "^2.7.5",
    "mocha": "^10.2.0",
    "nodemon": "^3.1.3",
    "patch-package": "^6.5.1",
    "prettier": "^2.8.7",
    "supertest": "^6.3.3",
    "terser-webpack-plugin": "^5.3.7",
    "webpack-bundle-analyzer": "^4.8.0",
    "webpack-dev-server": "^4.13.3"
  }
,
"build":"master-22595-v2.3.23"}
