import { Component, inject } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { PrefixSubscriptionPipe, TranslatePipe } from '../../../../utils/pipes';
import { RouterModule } from '@angular/router';
import { LegacyLinkInnerDirective } from '../../../../utils/directives';
import { DefaultService } from '../../../../utils/api';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-global-notifications',
    imports: [TranslatePipe, PrefixSubscriptionPipe, RouterModule, LegacyLinkInnerDirective, DatePipe],
    templateUrl: './global-notifications.component.html',
    styleUrl: './global-notifications.component.scss'
})
export class GlobalNotificationsComponent {
    defaultService = inject(DefaultService);
    authService = inject(AuthService);
    user = this.authService.user;

    hideBillingNotification() {
        this.defaultService
            .billingHideNotification({
                severity: this.user().notification.severity,
                targetSubscriptionId: this.user().subscriptionId
            })
            .subscribe();
        this.user.update((user) => ({ ...user, notification: null }));
    }
}
