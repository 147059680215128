import { Component, Input } from '@angular/core';
import { TranslatePipe } from '../../../../utils/pipes';

@Component({
    selector: 'app-top',
    imports: [TranslatePipe],
    templateUrl: './top.component.html',
    styleUrl: './top.component.scss'
})
export class TopComponent {
    @Input() fullWidth = false;
    @Input() boxedWidth = false;
}
