import { Pipe, PipeTransform, inject } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../shared/services/language.service';
import dayjs from 'dayjs';
import { AuthService } from '../shared/services/auth.service';

@Pipe({ standalone: true, name: 'prettyDateTime' })
export class PrettyDateTimePipe implements PipeTransform {
    constructor(private translatePipe: TranslatePipe) {}
    transform(ts: string): string {
        if (!ts || !dayjs(ts).isValid()) return '-';
        return dayjs(ts).calendar(null, {
            sameDay: `[${this.translatePipe.transform('format_ts_today')}] [${this.translatePipe.transform(
                'format_ts_at'
            )}] HH:mm`,
            nextDay: `[${this.translatePipe.transform('format_ts_tomorrow')}] [${this.translatePipe.transform(
                'format_ts_at'
            )}] HH:mm`,
            lastDay: `[${this.translatePipe.transform('format_ts_yesterday')}] [${this.translatePipe.transform(
                'format_ts_at'
            )}] HH:mm`,
            nextWeek: `D MMMM YYYY [${this.translatePipe.transform('format_ts_at')}] HH:mm`,
            lastWeek: `D MMMM YYYY [${this.translatePipe.transform('format_ts_at')}] HH:mm`,
            sameElse: `D MMMM YYYY [${this.translatePipe.transform('format_ts_at')}] HH:mm`
        });
    }
}

@Pipe({ standalone: true, name: 'prettyDate' })
export class PrettyDatePipe implements PipeTransform {
    constructor(private translatePipe: TranslatePipe) {}
    transform(ts: string): string {
        if (!ts || !dayjs(ts).isValid()) return '-';
        return dayjs(ts).calendar(null, {
            sameDay: `[${this.translatePipe.transform('format_ts_today')}]`,
            nextDay: `[${this.translatePipe.transform('format_ts_tomorrow')}]`,
            lastDay: `[${this.translatePipe.transform('format_ts_yesterday')}]`,
            nextWeek: `D MMMM YYYY`,
            lastWeek: `D MMMM YYYY`,
            sameElse: `D MMMM YYYY`
        });
    }
}

@Pipe({ standalone: true, name: 'ngbToDate' })
export class ngbToDatePipe implements PipeTransform {
    transform(date: NgbDate): string {
        const dayjsObject = dayjs();
        dayjsObject.set('year', date.year);
        dayjsObject.set('month', date.month - 1);
        dayjsObject.set('day', date.day);
        if (!dayjsObject.isValid()) return null;
        return dayjsObject.format('YYYY-MM-DD');
    }
}

@Pipe({ standalone: true, name: 'dateToNgb' })
export class dateToNgbPipe implements PipeTransform {
    transform(date: string): NgbDate | NgbDateStruct {
        const dayjsObject = dayjs(date);
        if (!dayjsObject.isValid()) return null;
        const day = dayjsObject.date();
        const month = dayjsObject.month() + 1;
        const year = dayjsObject.year();
        return {
            year: year,
            month: month,
            day: day
        };
    }
}

@Pipe({ standalone: true, name: 'display' })
export class displayPipe implements PipeTransform {
    transform(value: string | number): string | number {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }
        return value;
    }
}

@Pipe({ standalone: true, name: 'floatDisplay' })
export class floatDisplayPipe implements PipeTransform {
    transform(value: any, type?: 'thousandSeparator'): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        if (!isNaN(parseFloat(value))) {
            const r = parseFloat(value).toString().replace(/\./g, ',');
            if (type && type == 'thousandSeparator') {
                return r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            } else {
                return r;
            }
        }

        return value;
    }
}

@Pipe({ standalone: true, name: 'numberDisplay' })
export class numberDisplayPipe implements PipeTransform {
    transform(value: any, type?: 'thousandSeparator'): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        if (!isNaN(parseInt(value))) {
            const r = parseInt(value).toString();
            if (type && type == 'thousandSeparator') {
                return r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            } else {
                return r;
            }
        }

        return value;
    }
}

@Pipe({ standalone: true, name: 'customCurrency' })
export class customCurrencyPipe implements PipeTransform {
    currencyPipe = inject(CurrencyPipe);
    transform(value: any, round?: boolean): any {
        if (value === 0 || value === '0') {
            return round ? '€ 0' : '€ 0,00';
        }
        if (!value) {
            return '-';
        } else {
            const neg = value < 0;
            let returnValue = this.currencyPipe
                .transform(Math.abs(parseFloat(value)).toString(), '€')
                ?.replace(/,/g, '.');
            if (returnValue) {
                const n = returnValue.lastIndexOf('.');
                if (n >= 0 && returnValue.length) {
                    returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
                }
                if (round) {
                    returnValue = returnValue.substring(0, returnValue.length - 3);
                }
                if (neg) {
                    returnValue = `-${returnValue}`;
                }
            }
            return returnValue;
        }
    }
}

@Pipe({
    name: 'removeWhiteSpace',
    standalone: true
})
export class RemoveWhiteSpacePipe implements PipeTransform {
    transform(value: any): any {
        if (value === undefined) return 'undefined';
        return value.replace(/\s/g, '');
    }
}

@Pipe({
    name: 'address',
    standalone: true
})
export class AddressPipe implements PipeTransform {
    transform(address: string): string {
        return address
            .trim()
            .replace(/undefined/g, '')
            .replace(/ +(?= )/g, '')
            .replace(/ , /g, ', ')
            .replace(/, ,/g, ',')
            .replace(/,(\s+)?$/, '')
            .replace(/,(\s+)?$/, '')
            .replace(/,(\s+)?$/, '')
            .trim()
            .replace(/^,/, '')
            .trim();
    }
}

@Pipe({ standalone: true, name: 'customPercentage' })
export class percentagePipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '0%';
        }
        if (!value) {
            return '-';
        } else return value.toString().replace('.', ',') + '%';
    }
}

@Pipe({ standalone: true, name: 'translate' })
export class TranslatePipe implements PipeTransform {
    languageService = inject(LanguageService);
    public transform(key: string, replace?: Record<string, string | number>): string {
        let result = this.languageService.getTranslation(key);
        if (replace) {
            for (const key in replace) {
                result = result.replaceAll(`{${key}}`, replace[key].toString());
            }
            for (const key in replace) {
                result = result.replaceAll(
                    `{(${key})}`,
                    replace[key].toString()[0].toUpperCase() + replace[key].toString().slice(1)
                );
            }
            for (const key in replace) {
                result = result.replaceAll(`{[${key}]}`, replace[key].toString().toLowerCase());
            }
        }
        return result;
    }
}

@Pipe({ standalone: true, name: 'prefixLang' })
export class PrefixLangPipe implements PipeTransform {
    languageService = inject(LanguageService);
    public transform(path: string, noTrailingSlash?: boolean): string {
        const lang = this.languageService.getLanguage();
        return `/${lang}${noTrailingSlash ? '' : '/'}${path}`;
    }
}

@Pipe({ standalone: true, name: 'prefixSubscription' })
export class PrefixSubscriptionPipe implements PipeTransform {
    authService = inject(AuthService);
    public transform(path: string, noTrailingSlash?: boolean): string {
        const type = this.authService.user().type;
        const id = this.authService.user().subscriptionId;
        return `/${type}/${id}${noTrailingSlash ? '' : '/'}${path}`;
    }
}

@Pipe({ standalone: true, name: 'array' })
export class ArrayPipe implements PipeTransform {
    public transform(arr: any[]): string {
        if (!arr?.length) return '-';
        return arr.join(', ');
    }
}

@Pipe({
    name: 'safe',
    standalone: true
})
export class SafePipe implements PipeTransform {
    sanitizer = inject(DomSanitizer);

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}

@Pipe({
    name: 'callback',
    pure: false,
    standalone: true
})
export class callbackPipe implements PipeTransform {
    transform(items: any[], callback: (item: any, data?: any) => boolean, data?): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter((item) => callback(item, data));
    }
}

type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
    [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
    bytes: 0,
    KB: 0,
    MB: 1,
    GB: 1,
    TB: 2,
    PB: 2
};
@Pipe({ standalone: true, name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
    private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    transform(bytes = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

        let unitIndex = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unitIndex++;
        }

        const unit = this.units[unitIndex];

        if (typeof precision === 'number') {
            return `${bytes.toFixed(+precision)} ${unit}`;
        }
        return `${bytes.toFixed(precision[unit])} ${unit}`;
    }
}

@Pipe({ standalone: true, name: 'vat' })
export class vatPipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }
        return `${value.substring(0, 4)}.${value.substring(4, 7)}.${value.substring(7, value.length)}`;
    }
}

@Pipe({ standalone: true, name: 'ven' })
export class venPipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }
        return `${value.substring(0, 1)}.${value.substring(1, 4)}.${value.substring(4, 7)}.${value.substring(
            7,
            value.length
        )}`;
    }
}

@Pipe({ standalone: true, name: 'objLen' })
export class objLenPipe implements PipeTransform {
    transform(obj: any): any {
        return Object.keys(obj)?.length;
    }
}

@Pipe({ standalone: true, name: 'searchBody' })
export class searchBodyPipe implements PipeTransform {
    transform(SEARCH: any, SORT: any): any {
        // return this.searchService.getSearchBody(SEARCH, SORT, null, null);
    }
}

@Pipe({ standalone: true, name: 'stringify' })
export class stringifyPipe implements PipeTransform {
    transform(val): any {
        return JSON.stringify(val);
    }
}

@Pipe({ standalone: true, name: 'objToParams' })
export class objToParamsPipe implements PipeTransform {
    transform(obj: any): any {
        let queryParameters = new HttpParams();
        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                obj[key].forEach((element) => {
                    queryParameters = addToHttpParams(queryParameters, element, `${key}[]`);
                });
            } else {
                queryParameters = addToHttpParams(queryParameters, obj[key], key);
            }
        }
        return queryParameters;
    }
}

function addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
        httpParams = addToHttpParamsRecursive(httpParams, value);
    } else {
        httpParams = addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
}

function addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
        return httpParams;
    }

    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            (value as any[]).forEach((elem) => (httpParams = addToHttpParamsRecursive(httpParams, elem, key)));
        } else if (value instanceof Date) {
            if (key != null) {
                httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
            } else {
                throw Error('key may not be null if value is Date');
            }
        } else {
            Object.keys(value).forEach(
                (k) => (httpParams = addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
            );
        }
    } else if (key != null) {
        httpParams = httpParams.append(key, value);
    } else {
        throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
}

@Pipe({ standalone: true, name: 'formatBoolean' })
export class formatBooleanPipe implements PipeTransform {
    translatePipe = inject(TranslatePipe);
    transform(value: any): any {
        if (value === 1 || value === true || value === 'TRUE') {
            return this.translatePipe.transform('misc_yes');
        }
        if (value === 0 || value === false || value === 'FALSE') {
            return this.translatePipe.transform('misc_no');
        }
        return value;
    }
}

@Pipe({ standalone: true, name: 'noDash' })
export class noDashPipe implements PipeTransform {
    transform(value: string): any {
        if (value === '-') {
            return '';
        } else return value;
    }
}

@Pipe({ standalone: true, name: 'emailObfuscation' })
export class emailObfuscationPipe implements PipeTransform {
    public transform(key: string): string {
        return key.split('{at}').join('@').split('{dot}').join('.');
    }
}

@Pipe({
    name: 'minutesToDuration',
    standalone: true
})
export class minutesToDurationPipe implements PipeTransform {
    translatePipe = inject(TranslatePipe);
    transform(minutes: number, type: 'abbr' | 'full' = 'abbr'): string {
        let afterMinutes = `${this.translatePipe.transform('minute_abbr')}`;
        let afterHours = `${this.translatePipe.transform('hour_abbr')}`;
        if (type === 'full') {
            afterMinutes = ` ${this.translatePipe.transform('misc_minutes')}`;
            afterHours = ` ${this.translatePipe.transform('misc_hours')}`;
        }
        if (minutes > 0 && minutes / 60 < 1) {
            return minutes + `${minutes}${afterMinutes}`;
        } else {
            return `${minutes / 60}${afterHours}`;
        }
    }
}

@Pipe({
    name: 'leadingZeros',
    standalone: true
})
export class leadingZerosPipe implements PipeTransform {
    transform(number: number | string, size: number): string {
        return number.toString().padStart(size, '0');
    }
}

@Pipe({
    name: 'removeHttp',
    standalone: true
})
export class removeHttpPipe implements PipeTransform {
    transform(url: string): string {
        if (!url?.length) return '';
        return url.replace(/^(https?:\/\/)/, '');
    }
}

export function addhttp(url: string) {
    if (!url) return '';
    // eslint-disable-next-line no-useless-escape
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) url = 'http://' + url;
    return url;
}
