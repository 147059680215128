import { Component, HostListener, OnInit, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { TranslatePipe, PrefixSubscriptionPipe } from '../../../../utils/pipes';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AvatarComponent } from '../../misc/avatar/avatar.component';
import { DefaultService, NotificationEvent } from '../../../../utils/api';
import { Subscription } from 'rxjs';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { LegacyLinkInnerDirective } from '../../../../utils/directives';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-navigation',
    imports: [
        RouterModule,
        AvatarComponent,
        NgbPopoverModule,
        TranslatePipe,
        LegacyLinkInnerDirective,
        PrefixSubscriptionPipe,
        CommonModule
    ],
    templateUrl: './navigation.component.html',
    styleUrl: './navigation.component.scss'
})
export class NavigationComponent implements OnInit {
    // navItems: WritableSignal<NavItem[]> = signal([]);

    // navItemsLeft: Signal<NavItem[]> = computed(() => this.navItems().filter((x) => x.position === 'left'));
    // navItemsRight: Signal<NavItem[]> = computed(() => this.navItems().filter((x) => x.position === 'right'));

    authService = inject(AuthService);
    translatePipe = inject(TranslatePipe);
    prefixSubscriptionPipe = inject(PrefixSubscriptionPipe);
    router = inject(Router);
    defaultService = inject(DefaultService);

    user = this.authService.user;

    notifications = signal<NotificationEvent[]>([]);
    unreadNotifications = computed(() => this.notifications().filter((x) => !x.read));
    notificationsSubscription: Subscription;
    currentNotificationsOpened = false;
    previousPathname: string;

    switchingToAccountId: number;

    // legacy
    angularNavCodes: string[] = ['portfolio', 'assistants'];

    ngOnInit() {
        this.getNotifications();
        this.router.events.subscribe((next) => {
            if (next instanceof NavigationEnd && window.location.pathname !== this.previousPathname) {
                this.previousPathname = window.location.pathname;
                this.getNotifications();
            }
        });
    }

    getNotifications() {
        this.previousPathname = window.location.pathname;
        this.notificationsSubscription?.unsubscribe();
        this.notificationsSubscription = this.defaultService
            .notificationGetNotificationsForSubscription({
                importance: [1]
            })
            .subscribe((next) => {
                this.notifications.set(next);
                this.currentNotificationsOpened = false;
            });
    }

    onOpenNotifications() {
        this.currentNotificationsOpened = true;
    }

    onCloseNotifications() {
        if (this.unreadNotifications()?.length) {
            this.defaultService
                .notificationMarkNotificationAsRead({ ids: this.unreadNotifications().map((x) => x.id) })
                .subscribe(() => {
                    this.notifications.update((value) => value.map((notif) => ({ ...notif, read: true })));
                });
        }
    }

    switchAccount($event: Event, id: number) {
        if (id === this.user().subscriptionId) {
            $event.preventDefault();
            return;
        }
        this.switchingToAccountId = id;
    }
}
