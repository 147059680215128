import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { AppService } from '../shared/services/app.service';
import { LanguageService } from '../shared/services/language.service';
import { RecaptchaService } from '../shared/services/recaptcha.service';
import { ToastrService } from 'ngx-toastr';

export const ApiInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const appService = inject(AppService);
    const authService = inject(AuthService);
    const languageService = inject(LanguageService);
    const recaptchaService = inject(RecaptchaService);
    const activatedRoute = inject(ActivatedRoute);
    const toastrService = inject(ToastrService);

    const currentV3Token = recaptchaService.currentV3Token();
    const currentV2Token = recaptchaService.currentV2Token();
    let clonedRequest = request.clone();
    if (currentV3Token) {
        clonedRequest = clonedRequest.clone({
            headers: clonedRequest.headers.append('captcha-token', currentV3Token)
        });
        recaptchaService.currentV3Token.set(null);
    }
    if (currentV2Token) {
        clonedRequest = clonedRequest.clone({
            headers: clonedRequest.headers.append('captcha-token-v2', currentV2Token)
        });
        recaptchaService.currentV2Token.set(null);
    }

    clonedRequest = clonedRequest.clone({
        headers: clonedRequest.headers.append('Accept-Language', languageService.getLanguage())
    });

    if (clonedRequest.method == 'GET') {
        const version = `${appService.getVersionNumber()}-${languageService.getLanguage()}`;
        clonedRequest = clonedRequest.clone({
            setParams: {
                v: version
            }
        });
    }
    return next(clonedRequest).pipe(
        catchError((error) => {
            if (checkNoNetworkConnection(error)) {
                toastrService.error('No network connection. Please try again later.');
                const error = new Error('No network connection');
                console.error(error);
            }
            let route = activatedRoute.snapshot;
            while (route.firstChild) route = route.firstChild;
            switch (error.status) {
                case 400:
                    // validation
                    break;
                case 409:
                    // bad request
                    break;
                case 401:
                    if (!route.data['public']) authService.onUnauthorized();
                    break;
                case 500:
                case 502:
                case 403:
                case 429:
                case 404:
                case 410:
                    showFailWhale(error);
                    break;
            }
            return throwError(() => error);
        })
    );
};

const showFailWhale = (error) => {
    setTimeout(() => {
        const msg = `${error.error}`;
        document.querySelector('body').innerHTML = msg;
    }, 1);
};

const checkNoNetworkConnection = (error: any): boolean => {
    return (
        error instanceof HttpErrorResponse &&
        !error.headers.keys().length &&
        !error.ok &&
        !error.status &&
        !error.error.loaded &&
        !error.error.total
    );
};
