/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type UserResourceType = 'jobTitle' | 'civilState' | 'pronoun' | 'country' | 'nationality' | 'languageSkill' | 'title' | 'driverLicense' | 'hasOwnCar';

export const UserResourceType = {
    JobTitle: 'jobTitle' as UserResourceType,
    CivilState: 'civilState' as UserResourceType,
    Pronoun: 'pronoun' as UserResourceType,
    Country: 'country' as UserResourceType,
    Nationality: 'nationality' as UserResourceType,
    LanguageSkill: 'languageSkill' as UserResourceType,
    Title: 'title' as UserResourceType,
    DriverLicense: 'driverLicense' as UserResourceType,
    HasOwnCar: 'hasOwnCar' as UserResourceType
};

